import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img className="splash-img" src="https://scontent-sjc3-1.xx.fbcdn.net/v/t1.0-9/46492362_1463925107043465_1541608378378223616_n.jpg?_nc_cat=102&_nc_ht=scontent-sjc3-1.xx&oh=12f9010615f7619b65ca51303717cfb8&oe=5CDC5552" />
          <p className="slogan">
            Coming Soon..
          </p>
        </header>
      </div>
    );
  }
}

export default App;
